import React from "react";
import { Helmet } from "react-helmet";

const Seo = () => (
  <Helmet
    title="Amber & Lucas"
    meta={[
      {
        name: "description",
        content: "Amber & Lucas - December 31st, 2020 Wedding",
      },
    ]}
  />
);

export default Seo;
