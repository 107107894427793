import * as React from "react";
import ReactPlayer from "react-player";

import Layout from "../components/layout";

const main = () => (
  <Layout>
    <ReactPlayer
      controls={true}
      style={{ maxWidth: `100%` }}
      url="https://youtu.be/NPQJwqsvbXQ"
    />
  </Layout>
);

export default main;
