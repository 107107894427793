import React from "react";
import Seo from "../components/seo";

const backgroundColor = "#303841";

const Layout = ({ children }) => (
  <>
    <style jsx global>{`
      body {
        background-color: ${backgroundColor};
        margin: 0;
        padding: 0;
      }
    `}</style>
    <Seo />
    <div
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "100%",
      }}
    >
      {children}
    </div>
  </>
);

export default Layout;
